import Navbar from "./ContentHeader";
import Footer from "./ContentFooter";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

export default function LoginForm() {
  const [t, i18n] = useTranslation("global");
  const navigate = useNavigate();

  const login = async () => {
    event.preventDefault();
    const email = document.getElementById("email").value;
    const password = document.getElementById("password").value;
    const auth = getAuth();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        //console.log(user);
        localStorage.setItem("user", JSON.stringify(user)); // Store user information in local storage
        // window.location.href = "/UserProfile";
        navigate("/profile", { replace: true }); // Navigate to UserProfile page
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        //alert(errorMessage);
        console.log(errorCode, errorMessage);
        if (errorCode === "auth/wrong-password") {
          alert(t("Alert1.text"));
        } else if (errorCode === "auth/user-not-found") {
          alert(t("Alert2.text"));
        } else if (errorCode === "auth/network-error") {
          alert(t("Alert3.text"));
        } else if (errorCode === "auth/invalid-credential") {
          alert(t("Alert4.text"));
        } else {
          alert(t("Alert5.text") + errorMessage);
        }
      });
  };

  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]);
  return (
    <>
      <Navbar />

      <div className="flex items-center justify-center w-full relative h-screen bg-center bg-no-repeat bg-[url('./assets/images/home.jpeg')]">
        <div className="bg-gray-800 bg-blend-multiply bg-opacity-50 shadow-md rounded-lg px-8 py-6 max-w-md backdrop-filter backdrop-blur-md">
          <h1 className="text-2xl font-bold text-center mb-4 dark:text-gray-200">
            {t("Login.text")}
          </h1>
          <form action="#">
            <div className="mb-4">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
              >
                {t("Email.text")}
              </label>
              <input
                type="email"
                id="email"
                className="shadow-sm rounded-md w-full px-3 py-2 border-2 focus:outline-none focus:border-[#fcab21] focus:ring-[#fcab21]"
                placeholder="flyxom@email.com"
                required
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
              >
                {t("Password.text")}
              </label>
              <input
                type="password"
                id="password"
                className="shadow-sm rounded-md w-full px-3 py-2 border-2 focus:outline-none focus:border-[#fcab21] focus:ring-[#fcab21]"
                placeholder={t("Password_1.text")}
                required
              />
              <NavLink
                to={"/passwordreset"}
                className="text-xs text-white hover:text-[#fcab21] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {t("Forgot_Password.text")}
              </NavLink>
            </div>
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="remember"
                  className="h-4 w-4 rounded border-[#fcab21] focus:ring-[#fcab21]"
                />
                <label
                  htmlFor="remember"
                  className="ml-2 block text-sm text-gray-300"
                >
                  {t("Remember.text")}
                </label>
              </div>
              <NavLink
                to={"/registry"}
                className="text-xs text-gray-300 hover:text-[#fcab21] focus:outline-none"
              >
                {t("Create_Account.text")}
              </NavLink>
            </div>
            <button
              onClick={login}
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm text-white font-semibold bg-[#c79810] hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2"
            >
              {t("Login.text")}
            </button>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
}
