import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import "modern-normalize/modern-normalize.css";
import "./index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import LoginForm from "./components/LoginForm.jsx";
import ResetPassword from "./components/PasswordReset.jsx";
import RegistryForm from "./components/Registry.jsx";
import Profile from "./components/UserProfile.jsx";
import Survey from "./components/Survey.jsx";
import Claim from "./components/Claim.jsx";
import i18next from "i18next";
import { I18nextProvider } from "react-i18next";
import global_en from "./components/translations/en/global.json";
import global_es from "./components/translations/es/global.json";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_URL,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

i18next.init({
  interpolation: { escapeValue: false },
  lng: "en",
  resources: {
    en: {
      global: global_en,
    },
    es: {
      global: global_es,
    },
  },
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/loginform",
    element: <LoginForm />,
  },
  {
    path: "/passwordreset",
    element: <ResetPassword />,
  },
  {
    path: "/registry",
    element: <RegistryForm />,
  },
  {
    path: "profile",
    element: <Profile />,
  },
  {
    path: "/survey",
    element: <Survey />,
  },
  {
    path: "/claim",
    element: <Claim />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <RouterProvider router={router} />
    </I18nextProvider>
  </React.StrictMode>
);
