import Body from "./components/ContentBody";
import Footer from "./components/ContentFooter";
import Navbar from "./components/ContentHeader";

export default function App() {
  return (
    <>
      <Navbar />
      <Body />
      <Footer />
    </>
  );
}
