import Navbar from "./ContentHeader";
import Footer from "./ContentFooter";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

export default function PasswordReset() {
  const [t, i18n] = useTranslation("global");
  
  const resetPassword = async (event) => {
    event.preventDefault();
    const email = document.getElementById('email').value;
    const auth = getAuth();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        alert("Password reset email sent!");
      })
      .catch((error) => {
        alert(error.message);
      });
  };


  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]);
  return (
    <>
      <Navbar />

      <div className="flex items-center justify-center w-full relative h-screen bg-center bg-no-repeat bg-[url('./assets/images/home.jpeg')]">
        <div className="bg-gray-800 bg-blend-multiply bg-opacity-50 shadow-md rounded-lg px-8 py-6 max-w-md backdrop-filter backdrop-blur-md">
          <h1 className="text-2xl font-bold text-center mb-4 dark:text-gray-200">
            {t("Forgot_Password.text")}
          </h1>
          <p className="text-md font-normal text-center mb-4 dark:text-gray-200">
            {t("Forgot_Message.text")}
          </p>
          <form action="#">
            <div className="mb-4">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
              >
                {t("Email.text")}
              </label>
              <input
                type="email"
                id="email"
                className="shadow-sm rounded-md w-full px-3 py-2 border-2 focus:outline-none focus:border-[#fcab21] focus:ring-[#fcab21]"
                placeholder="flyxom@email.com"
                required
              />
            </div>
            <button
              type="submit"
              className="w-full flex justify-center py-2 px-4 border border-transparent mt-2mb-8 rounded-md shadow-sm text-sm text-white font-semibold bg-[#fcab21] focus:outline-none focus:ring-2 focus:ring-offset-2 "
              onClick={resetPassword}
            >
              {t("Reset_Password.text")}
            </button>
          </form>
          <div className="flex justify-center items-center mt-6">
            <NavLink to={"/LoginForm"}>
              <span className="ml-2 inline-flex items-center text-white font-medium text-xs text-center">
                {t("Have_Account.text")}
                <NavLink
                  to={"/LoginForm"}
                  className="text-xs ml-2 text[#fcab21] font-semibold text-[#fcab21] "
                >
                  {t("Login.text")}
                </NavLink>
              </span>
            </NavLink>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
