import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getAuth, signOut } from "firebase/auth";
import { getDatabase, ref, get } from "firebase/database";
import { useNavigate } from "react-router-dom";

export default function Profile() {
  const navigate = useNavigate();
  const [t, i18n] = useTranslation("global");

  const changeLanguage = (lng) => {
    localStorage.setItem("language", lng);
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }

    const user = JSON.parse(localStorage.getItem("user"));
    const auth = getAuth();
    if (user || auth.currentUser) {
      // Fetch user data based on UID
      getUserDataByUID(user.uid).then((userData) => {
        if (userData) {
          // Set user data in state

          setUserData(userData);

          localStorage.setItem("userData", JSON.stringify(userData)); // Store user data in local storage
        }
      });
    } else if (!user || !auth.currentUser) {
      // If user is not authenticated, redirect to login page
      console.log("User is not authenticated");
    }
  }, [i18n]);

  const setUserData = (userData) => {
    // Extract the necessary data from userData object
    const {
      firstName,
      lastName,
      email,
      address,
      city,
      state,
      country,
      nationality,
      phoneNumber,
      mobile,
      tourism_development,
      membership,
      flyxom_membership,
      coowner,
      date,
      membercountry,
      memberstate,
      membercity,
    } = userData;

    // Update the personalFields array with the new values
    const updatedPersonalFields = personalFields.map((field) => {
      if (field.name === "First_Name.text") {
        return { ...field, value: firstName };
      } else if (field.name === "Last_Name.text") {
        return { ...field, value: lastName };
      } else if (field.name === "Email.text") {
        return { ...field, value: email };
      } else if (field.name === "Address.text") {
        return { ...field, value: address };
      } else if (field.name === "City.text") {
        return { ...field, value: city };
      } else if (field.name === "State.text") {
        return { ...field, value: state };
      } else if (field.name === "Country.text") {
        return { ...field, value: country };
      } else if (field.name === "Nationality.text") {
        return { ...field, value: nationality };
      } else if (field.name === "Phone.text") {
        return { ...field, value: phoneNumber };
      } else if (field.name === "Mobile.text") {
        return { ...field, value: mobile };
      } else {
        return field; // No change for other fields
      }
    });
    // Update the personalFields with the updated array
    setPersonalFields(updatedPersonalFields);

    const updateMembershipFields = membershipFields.map((field) => {
      if (field.name === "Coowner Name.text") {
        return { ...field, value: coowner };
      } else if (field.name === "Tourism Development.text") {
        return { ...field, value: tourism_development };
      } else if (field.name === "Membership.text") {
        return { ...field, value: membership };
      } else if (field.name === "Flyxom_Membership.text") {
        return { ...field, value: flyxom_membership };
      } else if (field.name === "Purchase Date.text") {
        return { ...field, value: date };
      } else if (field.name === "City Of Purchase.text") {
        return { ...field, value: membercity };
      } else if (field.name === "Country Of Purchase.text") {
        return { ...field, value: membercountry };
      } else if (field.name === "State Of Purchase.text") {
        return { ...field, value: memberstate };
      } else {
        return field; // No change for other fields
      }
    });

    setMembershipFields(updateMembershipFields);
  };
  const [personalFields, setPersonalFields] = useState([
    {
      id: "1",
      name: "First_Name.text",
      value: "",
    },
    {
      id: "2",
      name: "Last_Name.text",
      value: "",
    },
    {
      id: "3",
      name: "Address.text",
      value: "",
    },
    {
      id: "4",
      name: "City.text",
      value: "",
    },
    {
      id: "5",
      name: "State.text",
      value: "",
    },
    {
      id: "6",
      name: "Country.text",
      value: "",
    },
    {
      id: "7",
      name: "Nationality.text",
      value: "",
    },
    {
      id: "8",
      name: "Email.text",
      value: "",
    },
    {
      id: "9",
      name: "Phone.text",
      value: "",
    },
    {
      id: "10",
      name: "Mobile.text",
      value: "",
    },
  ]);

  const [membershipFields, setMembershipFields] = useState([
    {
      id: "1",
      name: "Coowner Name.text",
      value: "",
    },
    {
      id: "2",
      name: "Tourism Development.text",
      value: "",
    },
    {
      id: "3",
      name: "Membership.text",
      value: "",
    },
    {
      id: "4",
      name: "Flyxom_Membership.text",
      value: "",
    },
    {
      id: "5",
      name: "Coowner Name.text",
      value: "",
    },
    {
      id: "6",
      name: "Purchase Date.text",
      value: "",
    },
    {
      id: "7",
      name: "Country Of Purchase.text",
      value: "",
    },
    {
      id: "8",
      name: "State Of Purchase.text",
      value: "",
    },
    {
      id: "9",
      name: "Country Of Purchase.text",
      value: "",
    },
    {
      id: "10",
      name: "",
      value: "",
    },
  ]);

  function componentfield(field) {
    return (
      <li className="flex border-y py-2" key={field.id}>
        <span className="font-bold"> {t(field.name)}:</span>
        <span className="text-white pl-2">{field.value}</span>
      </li>
    );
  }

  function membershipInfo(field) {
    return (
      <li className="flex border-y py-2" key={field.id}>
        <span className="font-bold"> {t(field.name)}:</span>
        <span className="text-white pl-2">{field.value}</span>
      </li>
    );
  }
  return (
    <div className="absolute lg:h-screen w-full bg-center bg-cover lg:bg-no-repeat bg-[url('./assets/images/home.jpeg')]">
      <div className="w-full h-[180px] bg-grey-200 bg-gradient-to-b from-gray-900 to-gray-700 border-b-4 border-[#fcab21]">
        <div />
        <div> </div>
      </div>
      <div className="flex flex-col items-center -mt-20">
        <img
          src={new URL("../assets/images/user.jpg", import.meta.url).href}
          className="w-40 border-4 border-white rounded-full"
        />
        <div className="flex items-center space-x-2 mt-2">
          <p className="text-2xl text-white">{personalFields[0].value}</p>
          <span className="bg-blue-500 rounded-full p-1" title="Verified">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="text-gray-100 h-2.5 w-2.5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="4"
                d="M5 13l4 4L19 7"
              />
            </svg>
          </span>
        </div>
        <p className="text-white">{personalFields[1].value}</p>
        <p className="text-sm text-slate-50">{personalFields[2].value}</p>
      </div>
      <div className="flex-1 flex flex-col items-center lg:items-end justify-end px-8 mt-2">
        <div className="flex items-center space-x-4 mt-2">
          <button
            onClick={() => changeLanguage("es")}
            className="lg:inline-flex lg:w-auto w-full px-1 py-2 rounded text-white items-center justify-center hover:text-[#fcab21]"
          >
            ES
          </button>
          <button
            onClick={() => changeLanguage("en")}
            className="lg:inline-flex lg:w-auto w-full px-1 py-2 rounded text-white items-center justify-center hover:text-[#fcab21]"
          >
            EN
          </button>
          <button
            onClick={() => navigate("/claim", { replace: true })}
            className="flex items-center shadow-xl bg-[#fcab21] hover:bg-yellow-600 text-gray-100 font-semibold px-4 py-2 rounded-sm text-sm space-x-2 transition duration-100 h-8"
          >
            <span>{t("Claim.text")}</span>
          </button>
          <button
            onClick={logout}
            className="flex justify-center items-center text-center shadow-xl bg-[#fcab21] hover:bg-yellow-600 text-gray-100 font-semibold px-8 py-2 rounded-sm lg:text-sm text-xs transition duration-100 h-8"
          >
            <img
              className="w-6 h-4 text-white"
              src={new URL("../assets/images/logout.svg", import.meta.url).href}
              alt="Plane Icon"
            />
            <span ClassName="mx-2 px-2">{t("Logout.text")}</span>
          </button>
        </div>
      </div>
      <div className="">
        <div className="my-4 flex flex-col sm:flex-row sm:space-x-4">
          <div className="w-full flex flex-col 2xl:w-1/2">
            <div className="flex-1 rounded-lg shadow-xl p-8 mx-2 bg-gray-800 bg-blend-multiply bg-opacity-65 backdrop-filter backdrop-blur-md">
              <h4 className="text-xl text-yellow-500 font-bold">
                {t("Personal.text")}
              </h4>

              <ul className="mt-2 text-white">
                {personalFields.map(componentfield)}
              </ul>
            </div>
          </div>

          <div className="w-full flex flex-col 2xl:w-1/2 mt-6 sm:mt-0">
            <div className="flex-1 rounded-lg shadow-xl p-8 mx-2  bg-gray-800  bg-blend-multiply bg-opacity-65 backdrop-filter backdrop-blur-md">
              <h4 className="text-xl text-yellow-500 font-bold">
                {t("Booking.text")}
              </h4>
              <ul className="mt-2 text-white">
                {membershipFields.map(membershipInfo)}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
const logout = () => {
  const auth = getAuth();
  signOut(auth)
    .then(() => {
      // Sign-out successful.
      window.location.href = "/";
    })
    .catch((error) => {
      // An error happened.
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage);
      alert(errorMessage);
    });
};

// Function to fetch user data based on UID
const getUserDataByUID = async (uid) => {
  try {
    // Get a reference to the Realtime Database
    const db = getDatabase();

    // Construct a reference to the user node based on UID
    const userRef = ref(db, "users/" + uid);

    // Fetch the user data
    const snapshot = await get(userRef);

    // Check if user data exists
    if (snapshot.exists()) {
      // Extract the user data from the snapshot
      const userData = snapshot.val();
      return userData;
    } else {
      // User data not found
      return null;
    }
  } catch (error) {
    console.error("Error fetching user data:", error.message);
    throw error;
  }
};
