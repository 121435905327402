import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useForm, ValidationError } from "@formspree/react";

export default function Body() {
  const [t, i18n] = useTranslation("global");

  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]);
  function FormComponent() {
    const [state, handleSubmit] = useForm("mbjnbvjy");
    if (state.succeeded) {
      return (
        <p className="md:w-32 mx-auto flex justify-center items-center text-center bg-[#fcab21] hover:bg-gray-400 text-white font-bold py-3 px-6 mt-3 transition ease-in-out duration-300">
          {t("Contact_Form.text")}
        </p>
      );
    }
    return (
      <div id="contact" className="bg-gray-900 py-4">
        <div className="justify-center lg:w-1/2 mx-auto  ">
          <div>
            <h1 className=" text-center pt-6 text-gray-400 text-xl font-semibold">
              {t("ContactH.text")}
            </h1>{" "}
          </div>

          <form className="p-6 flex flex-col " onSubmit={handleSubmit}>
            <label htmlFor="email"></label>
            <input
              className="w-100 mt-2 py-3 px-3 rounded-lg justify-center text-center bg-gray-700 border border-gray-600 text-gray-400 font-semibold focus:border-[#fcab21] focus:outline-none"
              id="email"
              type="email"
              name="email"
              placeholder={t("Email.text")}
              autoComplete="email"
            />
            <ValidationError
              prefix="Email"
              field="email"
              errors={state.errors}
            />
            <textarea
              className="w-100 mt-2 py-3 px-3 rounded-lg justify-center text-center bg-gray-700 border border-gray-600 text-gray-400 font-semibold focus:border-[#fcab21] focus:outline-none"
              id="message"
              name="message"
              placeholder={t("Message.text")}
            />
            <ValidationError
              prefix="Message"
              field="message"
              errors={state.errors}
            />
            <button
              className="w-full flex justify-center my-2 items-center text-center bg-[#fcab21] hover:bg-gray-400 text-white font-bold py-3 px-6 rounded-lg transition ease-in-out duration-300"
              type="submit"
              disabled={state.submitting}
            >
              {t("Send.text")}
            </button>
          </form>
        </div>
      </div>
    );
  }

  return (
    <>
      <div>
        <section
          id="intro"
          className="relative bg-center bg-cover h-[40hv] bg-no-repeat bg-[url('./assets/images/home.jpeg')]"
        >
          <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50"></div>
          <div className="container mx-auto relative z-10 py-20 text-white">
            <div className="max-w-3xl mx-auto text-center pb-10">
              <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold mb-4">
                {t("Intro.text")}
              </h1>
              <h2 className="text-lg md:text-xl lg:text-2xl mb-6">
                {t("Intro2.text")}
              </h2>
              <p className="text-sm md:text-base lg:text-lg pb-12">
                {t("IntroP.text")}
              </p>
            </div>
            <div className="absolute bottom-0 left-1/2 pb-5">
              <button className="iconBtnPlane">
                <img
                  className="w-8 h-8 "
                  src={
                    new URL(
                      "../assets/images/avion-scroll.svg",
                      import.meta.url
                    ).href
                  }
                  alt="Plane Icon"
                />
              </button>
            </div>
          </div>
        </section>
      </div>
      <div className="pt-8">
        <section id="flyxom" className="flex flex-col md:flex-row items-center">
          <div className="md:w-1/2 md:order-1">
            <div className="flex justify-center md:justify-center">
              <div className="flyxom-text text-center md:text-center md:mx-auto md:pr-6 lg:pr-10 md:pl-6 lg:pl-10 pt-4 md:pt-0">
                <h2 className="text-3xl font-semibold">{t("WIF.text")}</h2>
                <p className="text-base md:text-lg pt-4 pb-4 pl-4 pr-4 md:pb-0 ">
                  {t("WIF2.text")}
                  <span className="text-[#fcab22] font-bold">
                    {" "}
                    {t("WIF2_1.text")} {""}
                  </span>
                  {t("WIF2_2.text")}
                  <span className="text-[#fcab22] font-bold">
                    {" "}
                    {t("WIF2_3.text")}
                  </span>{" "}
                  {t("WIF2_4.text")}
                </p>
              </div>
            </div>
          </div>
          <div className="md:w-1/2 md:order-2 pt-8">
            <div className="flex justify-center md:justify-end pb-8 md:pb-0">
              <img
                src={
                  new URL(
                    "../assets/images/mapa-responsive.png",
                    import.meta.url
                  ).href
                }
                alt="Map Image"
                className="max-w-full h-auto block"
              />
            </div>
          </div>
        </section>
      </div>
      <div>
        <section
          id="us"
          className="content flex flex-col md:flex-row items-center"
        >
          <div className="md:w-1/2 md:order-1">
            <img
              src={
                new URL(
                  "../assets/images/avion-last-section.png",
                  import.meta.url
                ).href
              }
              alt="avion-last-section"
              className="bg-no-repeat bg-center bg-cover w-full md:order-2"
            />
          </div>
          <div className="md:w-1/2 md:order-2 pl-2 pr-2">
            <div className="text-left md:text-left md:mx-auto">
              <h3 data-translate="US" className="text-3xl font-semibold pt-4">
                {t("US.text")}
              </h3>
              <h4 className="text-lg md:text-xl pt-4">
                <span className="text-[#fcab22] font-bold">Fly Xom </span>
                {t("US2.text")}
              </h4>
              <p className="text-sm md:text-base pb-4">{t("US3.text")}</p>
            </div>
          </div>
        </section>
      </div>
      <section>
        <FormComponent />
      </section>
    </>
  );
}
