import { useState, useEffect } from "react";
import Navbar from "./ContentHeader";
import Footer from "./ContentFooter";
import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  getAuth,
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getDatabase, ref, set } from "firebase/database";
import { Country, State, City } from "country-state-city";

// Initialize Firebase
const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_URL,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const database = getDatabase(firebaseApp);

export default function RegistryForm() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation("global");
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [states2, setStates2] = useState([]);
  const [cities2, setCities2] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phone, setPhoneNumber] = useState("");
  const [mobile, setMobile] = useState("");
  const [address, setAddress] = useState("");
  const [nationality, setNationality] = useState("");
  const [tourismDevelopment, setTourismDevelopment] = useState("");
  const [membership, setMembership] = useState("");
  const [flyxomMembership, setFlyxomMembership] = useState("");
  const [coowner, setCoowner] = useState("");
  const [purchasedate, setPurchasedate] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedCountry2, setSelectedCountry2] = useState("");
  const [selectedState2, setSelectedState2] = useState("");
  const [selectedCity2, setSelectedCity2] = useState("");

  useEffect(() => {
    setCountries(Country.getAllCountries());
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      setStates(State.getStatesOfCountry(selectedCountry));
      setCities([]); // Reset cities when country changes
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedState) {
      setCities(City.getCitiesOfState(selectedCountry, selectedState));
    }
  }, [selectedCountry, selectedState]);

  useEffect(() => {
    if (selectedCountry2) {
      setStates2(State.getStatesOfCountry(selectedCountry2));
      setCities2([]); // Reset cities when country changes
    }
  }, [selectedCountry2]);

  useEffect(() => {
    if (selectedState2) {
      setCities2(City.getCitiesOfState(selectedCountry2, selectedState2));
    }
  }, [selectedCountry2, selectedState2]);

  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]);

  const signUp = async (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    const firstName = document.getElementById("firstName").value;
    const lastName = document.getElementById("lastName").value;
    const email = document.getElementById("email").value;
    const password = document.getElementById("password").value;
    const address = document.getElementById("address").value;
    const country = document.getElementById("country").value;
    const state = document.getElementById("state").value;
    const city = document.getElementById("city").value;
    const nationality = document.getElementById("nationality").value;
    const phoneNumber = document.getElementById("phone").value;
    const mobile = document.getElementById("mobile").value;
    const tourism_development = document.getElementById(
      "tourism_development"
    ).value;
    const membership = document.getElementById("membership").value;
    const flyxom_membership =
      document.getElementById("flyxom_membership").value;
    const coowner = document.getElementById("coowner").value;
    const date = document.getElementById("date").value;
    const membercountry = document.getElementById("membercountry").value;
    const memberstate = document.getElementById("memberstate").value;
    const membercity = document.getElementById("membercity").value;

    const passwordPolicy = {
      minLength: 8,
      requireLowercase: true,
      requireUppercase: true,
      requireNumbers: true,
    };

    const isValidPassword = (password, policy) => {
      if (password.length < policy.minLength) return false;
      if (!/[a-z]/.test(password) && policy.requireLowercase) return false;
      if (!/[A-Z]/.test(password) && policy.requireUppercase) return false;
      if (!/\d/.test(password) && policy.requireNumbers) return false;
      if (
        !/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(password) &&
        policy.requireSpecialCharacters
      )
        return false;
      return true;
    };

    if (!isValidPassword(password, passwordPolicy)) {
      alert(t("Alert6.text"));
      return;
    }

    const auth = getAuth();
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      localStorage.setItem("userID", JSON.stringify(user.uid)); // Store userID in local storage

      // Write user data to database
      writeUserData(
        user.uid,
        firstName,
        lastName,
        email,
        address,
        city,
        state,
        country,
        nationality,
        phoneNumber,
        mobile,
        tourism_development,
        membership,
        flyxom_membership,
        coowner,
        date,
        membercountry,
        memberstate,
        membercity
      );

      await sendEmailVerification(user);

      alert(t("Welcome.text"));
      navigate("/Survey", { replace: true });
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      alert(t("Alert5.text"));
      console.log(errorCode, errorMessage);
    }
  };

  const writeUserData = (
    userId,
    firstName,
    lastName,
    email,
    address,
    city,
    state,
    country,
    nationality,
    phoneNumber,
    mobile,
    tourism_development,
    membership,
    flyxom_membership,
    coowner,
    date,
    membercountry,
    memberstate,
    memercity
  ) => {
    try {
      const db = getDatabase();
      set(ref(db, "users/" + userId), {
        firstName,
        lastName,
        email,
        address,
        city,
        state,
        country,
        nationality,
        phoneNumber,
        mobile,
        tourism_development,
        membership,
        flyxom_membership,
        coowner,
        date,
        membercountry,
        memberstate,
        memercity,
      });
    } catch (error) {
      console.log("Error", error);
      alert(error);
    }
  };
  return (
    <>
      <Navbar />
      <div className="flex items-center justify-center w-full relative h-screen bg-center bg-no-repeat bg-[url('./assets/images/home.jpeg')]">
        <div className="overflow-y-auto max-h-screen bg-gray-800 bg-blend-multiply bg-opacity-50 shadow-md rounded-lg px-8 py-8 backdrop-filter backdrop-blur-md w-[40rem]">
          <h1 className="text-2xl font-bold text-center mb-4 dark:text-gray-200">
            {t("Join.text")}
          </h1>
          <p className="border-b-2 border-gray-500"></p>
          <form action="#">
            <div className="mb-4 pt-4">
              <label
                htmlFor="first-name"
                className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
              >
                {t("First_Name.text")}
              </label>
              <input
                id="firstName"
                className="shadow-sm rounded-md w-full px-3 py-2 border-2 focus:outline-none focus:border-[#fcab21] focus:ring-[#fcab21]"
                placeholder="John"
                value={firstName}
                onChange={(event) => setFirstName(event.target.value)}
                required
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="-name"
                className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
              >
                {t("Last_Name.text")}
              </label>
              <input
                id="lastName"
                className="shadow-sm rounded-md w-full px-3 py-2 border-2 focus:outline-none focus:border-[#fcab21] focus:ring-[#fcab21]"
                placeholder="Doe"
                value={lastName}
                onChange={(event) => setLastName(event.target.value)}
                required
              />
            </div>

            <div className="mb-4">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
              >
                {t("Email.text")}
              </label>
              <input
                type="email"
                id="email"
                className="shadow-sm rounded-md w-full px-3 py-2 border-2 focus:outline-none focus:border-[#fcab21] focus:ring-[#fcab21]"
                placeholder="flyxom@email.com"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                required
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
              >
                {t("Password.text")}
              </label>
              <input
                type="password"
                id="password"
                className="shadow-sm rounded-md w-full px-3 py-2 border-2 focus:outline-none focus:border-[#fcab21] focus:ring-[#fcab21]"
                placeholder={t("Password_Registry.text")}
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                required
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="confirmPassword"
                className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
              >
                {t("Confirm_Password.text")}
              </label>
              <input
                type="password"
                id="confirmPassword"
                className={`shadow-sm rounded-md w-full px-3 py-2 border-2 focus:outline-none focus:border-[#fcab21] focus:ring-[#fcab21] ${
                  password !== confirmPassword && confirmPassword !== ""
                    ? "border-red-500"
                    : ""
                }`}
                placeholder={t("Confirm_Password.text")}
                value={confirmPassword}
                onChange={(event) => setConfirmPassword(event.target.value)}
                required
              />
              {password !== confirmPassword && confirmPassword !== "" && (
                <p className="text-red-500 text-sm mt-1">
                  {t("PasswordMismatch.text")}
                </p>
              )}
            </div>

            <div className="mb-4">
              <label
                htmlFor="address"
                className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
              >
                {t("Address.text")}
              </label>
              <input
                id="address"
                className="shadow-sm rounded-md w-full px-3 py-2 border-2 focus:outline-none focus:border-[#fcab21] focus:ring-[#fcab21]"
                placeholder="123 Main St"
                value={address}
                onChange={(event) => setAddress(event.target.value)}
                required
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="country"
                className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
              >
                {t("Country.text")}
              </label>
              <select
                id="country"
                className="shadow-sm rounded-md w-full px-3 py-2 border-2 focus:outline-none focus:border-[#fcab21] focus:ring-[#fcab21]"
                value={selectedCountry}
                onChange={(event) => {
                  setSelectedCountry(event.target.value);
                  setSelectedState("");
                  setSelectedCity("");
                }}
                required
              >
                <option value="">{t("Select_Country.text")}</option>
                {countries.map((country) => (
                  <option key={country.isoCode} value={country.isoCode}>
                    {country.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-4">
              <label
                htmlFor="state"
                className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
              >
                {t("State.text")}
              </label>
              <select
                id="state"
                className="shadow-sm rounded-md w-full px-3 py-2 border-2 focus:outline-none focus:border-[#fcab21] focus:ring-[#fcab21]"
                value={selectedState}
                onChange={(event) => setSelectedState(event.target.value)}
                required
              >
                <option value="">{t("Select_State.text")}</option>
                {states.map((state) => (
                  <option key={state.isoCode} value={state.isoCode}>
                    {state.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-4">
              <label
                htmlFor="city"
                className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
              >
                {t("City.text")}
              </label>
              <select
                id="city"
                className="shadow-sm rounded-md w-full px-3 py-2 border-2 focus:outline-none focus:border-[#fcab21] focus:ring-[#fcab21]"
                value={selectedCity}
                onChange={(event) => setSelectedCity(event.target.value)}
                required
              >
                <option value="">{t("Select_City.text")}</option>
                {cities.map((city) => (
                  <option key={city.name} value={city.name}>
                    {city.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-4">
              <label
                htmlFor="nationality"
                className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
              >
                {t("Nationality.text")}
              </label>
              <input
                id="nationality"
                className="shadow-sm rounded-md w-full px-3 py-2 border-2 focus:outline-none focus:border-[#fcab21] focus:ring-[#fcab21]"
                placeholder="Mexican"
                value={nationality}
                onChange={(event) => setNationality(event.target.value)}
                required
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="phone"
                className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
              >
                {t("Phone.text")}
              </label>
              <input
                id="phone"
                className="shadow-sm rounded-md w-full px-3 py-2 border-2 focus:outline-none focus:border-[#fcab21] focus:ring-[#fcab21]"
                placeholder="123-456-7890"
                value={phone}
                onChange={(event) => setPhoneNumber(event.target.value)}
                required
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="mobile"
                className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
              >
                {t("Mobile.text")}
              </label>
              <input
                id="mobile"
                className="shadow-sm rounded-md w-full px-3 py-2 border-2 focus:outline-none focus:border-[#fcab21] focus:ring-[#fcab21]"
                placeholder="123-456-7890"
                value={mobile}
                onChange={(event) => setMobile(event.target.value)}
                required
              />
            </div>
            <p className="pt-4 pb-4 border-b-2 border-gray-500 text-center  text-xl font-bold dark:text-gray-200">
              {t("Membership.text")}
            </p>

            <div className="mb-4 pt-4">
              <label
                htmlFor="tourism_development"
                className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
              >
                {t("Tourism Development.text")}
              </label>
              <input
                id="tourism_development"
                className="shadow-sm rounded-md w-full px-3 py-2 border-2 focus:outline-none focus:border-[#fcab21] focus:ring-[#fcab21]"
                placeholder="Vidanta"
                value={tourismDevelopment}
                onChange={(event) => setTourismDevelopment(event.target.value)}
                required
              />
            </div>

            <div className="mb-4">
              <label
                htmlFor="membership"
                className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
              >
                {t("Membership.text")}
              </label>
              <input
                id="membership"
                className="shadow-sm rounded-md w-full px-3 py-2 border-2 focus:outline-none focus:border-[#fcab21] focus:ring-[#fcab21]"
                placeholder="123-456-7890"
                value={membership}
                onChange={(event) => setMembership(event.target.value)}
                required
              />
            </div>

            <div className="mb-4">
              <label
                htmlFor="flyxom_membership"
                className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
              >
                {t("Flyxom_Membership.text")}
              </label>
              <input
                id="flyxom_membership"
                className="shadow-sm rounded-md w-full px-3 py-2 border-2 focus:outline-none focus:border-[#fcab21] focus:ring-[#fcab21]"
                placeholder="123-456-7890"
                value={flyxomMembership}
                onChange={(event) => setFlyxomMembership(event.target.value)}
                required
              />
            </div>

            <div className="mb-4">
              <label
                htmlFor="coowner"
                className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
              >
                {t("Coowner Name.text")}
              </label>
              <input
                id="coowner"
                className="shadow-sm rounded-md w-full px-3 py-2 border-2 focus:outline-none focus:border-[#fcab21] focus:ring-[#fcab21]"
                placeholder="Karen N"
                value={coowner}
                onChange={(event) => setCoowner(event.target.value)}
                required
              />
            </div>

            <div className="mb-4">
              <label
                htmlFor="date"
                className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
              >
                {t("Purchase Date.text")}
              </label>
              <input
                type="date"
                id="date"
                className="shadow-sm rounded-md w-full px-3 py-2 border-2 focus:outline-none focus:border-[#fcab21] focus:ring-[#fcab21]"
                value={purchasedate}
                onChange={(event) => setPurchasedate(event.target.value)}
                required
              />
            </div>

            <div className="mb-4">
              <label
                htmlFor="membercountry"
                className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
              >
                {t("Country.text")}
              </label>
              <select
                id="membercountry"
                className="shadow-sm rounded-md w-full px-3 py-2 border-2 focus:outline-none focus:border-[#fcab21] focus:ring-[#fcab21]"
                value={selectedCountry2}
                onChange={(event) => {
                  setSelectedCountry2(event.target.value);
                  setSelectedState2("");
                  setSelectedCity2("");
                }}
                required
              >
                <option value="">{t("Select_Country.text")}</option>
                {countries.map((country) => (
                  <option key={country.isoCode} value={country.isoCode}>
                    {country.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-4">
              <label
                htmlFor="memberstate"
                className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
              >
                {t("State.text")}
              </label>
              <select
                id="memberstate"
                className="shadow-sm rounded-md w-full px-3 py-2 border-2 focus:outline-none focus:border-[#fcab21] focus:ring-[#fcab21]"
                value={selectedState2}
                onChange={(event) => setSelectedState2(event.target.value)}
                required
              >
                <option value="">{t("Select_State.text")}</option>
                {states2.map((state) => (
                  <option key={state.isoCode} value={state.isoCode}>
                    {state.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-4">
              <label
                htmlFor="membercity"
                className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
              >
                {t("City.text")}
              </label>
              <select
                id="membercity"
                className="shadow-sm rounded-md w-full px-3 py-2 border-2 focus:outline-none focus:border-[#fcab21] focus:ring-[#fcab21]"
                value={selectedCity2}
                onChange={(event) => setSelectedCity2(event.target.value)}
                required
              >
                <option value="">{t("Select_City.text")}</option>
                {cities2.map((city) => (
                  <option key={city.name} value={city.name}>
                    {city.name}
                  </option>
                ))}
              </select>
            </div>

            <button
              onClick={signUp}
              type="submit"
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm text-white font-semibold bg-[#fcab21] focus:outline-none focus:ring-2 focus:ring-offset-2 "
            >
              {t("Create_Account.text")}
            </button>
            <p className="pb-4 pt-4 border-b-2 border-gray-500"></p>
          </form>
          <div className="flex justify-center items-center mt-6">
            <NavLink to={"/LoginForm"}>
              <span className="ml-2 inline-flex items-center text-white font-medium text-xs text-center">
                {t("Back_Login.text")}
                <NavLink
                  to={"/LoginForm"}
                  className="text-xs ml-2 text[#fcab21] font-semibold text-[#fcab21] "
                >
                  {t("Login.text")}
                </NavLink>
              </span>
            </NavLink>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
